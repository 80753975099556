import React from 'react';
import './Header.css';
import logo from './components/image/logo/Logo.png'
import { ReactComponent as Shop } from './components/image/Icon/chop_card.svg'

const Header = () => {
    return (
        <header className="header">
            <div className='iteams'>
                <div className='iteam_logo'><img src={logo} alt="Логотип" width="70" height="70" /></div>
                <div className='iteam_name'>digital square</div>
                <div className='iteam_chop'>
                    <Shop className='shop' /></div>
            </div>
        </header >);
};

export default Header