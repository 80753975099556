import React from 'react';
import './App.css';
import './container.css'
import Header from './Header';

function App() {
    return (

        <div className='container'>
            <div className="App"> <Header /> {/* Тут можна додати інші компоненти */} </div>;
        </div>);
}
export default App;